import { FC } from 'react';

import { LanguageSelectorItem, LanguageSelectorRendering } from '@sitecore/types/manual/LanguageSelector';
import { NavLink, Stack } from '@sparky';
import { useMediaQuery } from '@sparky/hooks';

import { LangaugeSelectorDropdown } from '../LangaugeSelectorDropdown/LangaugeSelectorDropdown';

const LanguageSelector: FC<LanguageSelectorRendering> = ({ fields, params }) => {
  if (!fields?.length) return null;

  const isDropdown = params?.RenderVariant === 'Dropdown';

  return isDropdown ? <LanguageSelectorDropdown fields={fields} /> : <LanguageSelectorList fields={fields} />;
};

const LanguageSelectorList = ({ fields }: { fields: LanguageSelectorItem[] }) => {
  const isDesktop = useMediaQuery('lg');

  return (
    <Stack direction="row" gap="4">
      {fields
        // desktop should show only the language that is not currently active
        .filter(language => (isDesktop ? !language.active : true))
        .map(language => {
          const props: { isCurrent: boolean; variant: 'primary' | 'tertiary' } = {
            isCurrent: isDesktop ? false : language.active,
            variant: isDesktop ? 'primary' : 'tertiary',
          };

          return (
            <NavLink href={language.url} key={language.language} {...props}>
              {language.languageDisplayName}
            </NavLink>
          );
        })}
    </Stack>
  );
};

const LanguageSelectorDropdown = ({ fields }: { fields: LanguageSelectorItem[] }) => {
  return <LangaugeSelectorDropdown languages={fields} />;
};
export default LanguageSelector;
